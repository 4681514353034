.radio-control-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    margin-bottom:16px;

    &.disabled {
        opacity: 0.6;
        pointer-events: none;
    }
    &.filled {
        border: 1px solid #1152F6 !important;
        box-shadow: 0px 0px 8px 0px rgba(27, 105, 223, 0.25) !important;
        background-color: #EBEDF5 !important;
    }


    &.space-between {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &.flex-start {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    &.row-reverse {
        flex-direction: row-reverse;
    }

    &.big {
        min-height: 40px;
        padding-top:8px;
        padding-bottom:8px;
        label {
            font-size:14px;
        }

    }

    &.tertiary {
        border-radius: 4px;
        padding-left:8px;
        padding-right: 8px;
        background-color: #EFEFEF;

        .p-radiobutton .p-radiobutton-box {
            background-color: #FFF;
        }
    }

    &.label-stretched {
        .label-content {
            flex-grow: 1;
        }
    }

    p-radiobutton {

        .p-radiobutton {


            .p-radiobutton-box {
                width: 16px;
                height: 16px;
                background-color: #E7E7E7;
                border:1px solid #E7E7E7;
                
            }
            &:not(.p-radiobutton-disabled){
                .p-radiobutton-box {

                    &.p-focus {
    
                        box-shadow: 0 0 0 0px #ffffff, 0 0 0 2px #E7E7E7, 0 1px 2px 0 rgba(0, 0, 0, 0);
                      
                    }
                  
    
                    
                }
             
            }

        }

        .p-radiobutton-focused {
            .p-radiobutton-box {
                width: 16px;
                height: 16px;
                
            }
            &:not(.p-radiobutton-disabled){
                .p-radiobutton-box {

                    &.p-focus {
    
                        box-shadow: 0 0 0 0px #ffffff, 0 0 0 2px #4252A3, 0 1px 2px 0 rgba(0, 0, 0, 0);
                      
                    }
                  
    
                    
                }
             
            }
        }

        .p-radiobutton-checked {
            .p-radiobutton-icon {
                background-color: #4252A3;
                width: 8px;
                height: 8px;
            }

   
            &:not(.p-radiobutton-disabled){
                .p-radiobutton-box {
                    background-color: transparent;
                    border:unset;
                
    
                        box-shadow: 0 0 0 0px #ffffff, 0 0 0 2px #4252A3, 0 1px 2px 0 rgba(0, 0, 0, 0);
                          
                    
                }
             
            }
        }
    }


    .label-content {
        display:flex;
        align-items: flex-start;
        flex-direction: column;
        row-gap:2px;
        height: 100%;
        cursor: pointer;
        .label-text {
            @include fontInter(12px, #000, 500);
            margin:unset;
        }

        .option-description {
            @include fontInter(12px, #6B6B6B, 400);  
            margin:unset;
        }
    } 

}