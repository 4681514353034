.internal-note-block {
    background-color: #FFF9C4;
    padding:8px;
    max-width: 166px;
    border-radius: 4px;
    cursor: pointer;
    p {
        @include fontInter(12px, #000, 500, 16px);   
        margin-bottom:unset;
        display: -webkit-box; /* Use flex-like behavior for block content */
        -webkit-line-clamp: 2; /* Limit to 2 lines */
        -webkit-box-orient: vertical; /* Set vertical orientation */
        overflow: hidden; /* Hide overflowing content */
        text-overflow: ellipsis; /* Add ellipsis */
    }
}