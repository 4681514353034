.p-badge {
    width: 16px;
    height: 16px;
    min-width: 16px;
    @include fontInter(11px, #FFF, 400, 16px);
    background-color: #4252A3;
    display: flex;
    align-items: center;
    justify-content: center;
  
    &.hidden {
      display:none;
    }
  }