
@mixin fontInter($size: false, $colour: false, $weight: false,  $lh: false, $ls: false) {
 // font-family: "Poppins", sans-serif;
  font-family: "Inter", sans-serif;
//  font-family: "Karla", sans-serif;
    @if $size {
      font-size: $size;
    }
  
    @if $colour {
      color: $colour;
    }
    
    @if $weight {
        font-weight: $weight;
      }

    @if $lh {
      line-height: $lh;
    }
  
    @if $ls {
      letter-spacing: $ls;
    }
  }