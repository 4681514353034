.table-outer-wrapper {
    p-tabView {
       .p-tabview-nav li .p-tabview-nav-link {
        @include fontInter(14px, #000, 500);
       } 

       .p-tabview-nav li.p-highlight .p-tabview-nav-link {
        @include fontInter(14px, #4252A3, 500);
       } 

       .p-tabview-ink-bar {
            background-color: #4252A3;
       }

       .p-tabview-nav-content {
        border-top-left-radius: 0.4375rem;
        border-top-right-radius: 0.4375rem;
       }
    }
}

.tabs-wrapper {
    &.in-dialog {
       .p-tabview-panels {
        padding-left:unset;
        padding-right: unset;
       } 
    }
    p-tabView {
        .p-tabview-nav li .p-tabview-nav-link {
         @include fontInter(14px, #000, 500);
        } 
 
        .p-tabview-nav li.p-highlight .p-tabview-nav-link {
         @include fontInter(14px, #4252A3, 500);
        } 
 
        .p-tabview-ink-bar {
             background-color: #4252A3;
        }
 
        .p-tabview-nav-content {
         border-top-left-radius: 0.4375rem;
         border-top-right-radius: 0.4375rem;
        }
     }
}