.input-control-wrapper {
  margin-bottom: 16px;
  position: relative;

    &.input-text-centered {
      input {
        text-align: center;
      }
    }

    &.b-700 {
      input {
        font-weight: 700 !important;
      }
    }


    &.no-label {
      input {
        padding-left:8px !important;
      }
    }
    
  .suffix-label {
    @include fontInter(10px, #A1A1A1, 500);
    padding-right: 8px;
    height: 100%;
    display:flex;
    align-items: center;
  }

  .suffix-icons {
    padding-right: 8px;
    height: 100%;
    display:flex;
    align-items: center;
    justify-content: center;
    overflow: unset;
    .clickable-action {
      cursor: pointer;
    }
  }
  .inline-label-text-control {
    display: flex;
    align-items: center;
    background: #EFEFEF;
    border: 1px solid transparent;
    border-radius: 4px;
    height: 40px;
    position: relative;
    margin-bottom: unset;

    &:hover {
      border: 1px solid #1152F6 !important;
      box-shadow: 0px 0px 8px 0px rgba(27, 105, 223, 0.25) !important;
    }

    &.focused {
      border: 1px solid #1152F6 !important;
      box-shadow: 0px 0px 8px 0px rgba(27, 105, 223, 0.25) !important;
    }

    &.sm {
      height: 36px;
    }

    .flag-block {
      height: 38px;
      min-width: 28px;
      width: 28px;
      border-radius: 3px;
      background: #6B6B6B;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: unset;
      border-bottom-right-radius: unset;

      .flag-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        img {
          height: 10px;
          margin-bottom: 3px;
        }

        p {
          @include fontInter(12px, #FFF, 400);
          text-transform: uppercase;
          margin-bottom: unset;
          text-align: center;
        }
      }


    }

    label {
      width: 40%;
      min-width: 40%;
      cursor: unset;
      @include fontInter(12px, #6B6B6B, 500);
      height: 100%;
      display: flex;
      align-items: center;
      padding-left: 8px;

      @include media-breakpoint-down(sm) {
        width: 40%;
        min-width: 40%;
      }
    }


    input {
      &.disabled {
        opacity: 0.6;
        cursor: pointer;
        pointer-events: none;
      }
      &:not(.p-autocomplete-input) {
        display: flex;
        width: 100%;
        height: 100%;
        border: unset;
        position: unset;
        background-color: unset !important;
        @include fontInter(12px, #000, 600);
        padding-left: unset;
        &::placeholder {
          @include fontInter(12px, #6B6B6B, 500);
        }
      }

    }


    &.primary {
      background: #EFEFEF;

      label {
        @include fontInter(12px, #6B6B6B, 500);
      }



    }


  }


  .error-text {
    padding-left: 12px;
    @include fontInter(10px, #DE1A22, 400);
    position: relative;
  }


  &.secondary {
    .inline-label-text-control {
      background-color: #FFF;
    }

    &.dark-borders {
      .inline-label-text-control {
        border:1px solid #000;
        border-radius: 4px;
      }
    }
    
  }


}




.input-default-wrapper {
  width: 100%;
  margin-bottom: 16px;
  border: 1px solid transparent;
  border-radius: 4px;
  &:hover {
    border: 1px solid #1152F6;
    box-shadow: 0px 0px 8px 0px rgba(27, 105, 223, 0.25);
  }

  &.focused {
    border: 1px solid #1152F6;
    box-shadow: 0px 0px 8px 0px rgba(27, 105, 223, 0.25);
  }

  label {
    display: block;
    @include fontInter(12px, #6B6B6B, 500);
    margin-bottom: 4px;
  }

  .error-text {
    padding-left: 12px;
    @include fontInter(10px, #DE1A22, 400);
    position: relative;
  }

  input {
    height: 40px;
    border: unset;
    box-shadow: unset;
    border-radius: 4px;
    background: #EFEFEF;
    padding-left: 8px;
    padding-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    @include fontInter(12px, #000, 500);

    &.border-radius-top-left-unset {
      border-top-left-radius: unset;
    }

    &.border-radius-bottom-left-unset {
      border-bottom-left-radius: unset;
    }

    &.border-radius-top-right-unset {
      border-top-left-radius: unset;
    }

    &.border-radius-bottom-right-unset {
      border-bottom-left-radius: unset;
    }

    &::placeholder {
      @include fontInter(12px, #6B6B6B, 500);
    }
  }

  &.secondary {
    input {
      background: #FFF;
    }
  }

}