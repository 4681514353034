

.small-dialog {
  width: 360px;
  @include media-breakpoint-down(lg){
    width: 92%;
  }
}

.medium-dialog {
  width: 448px;
  @include media-breakpoint-down(lg){
    width: 92%;
  }
}





.large-dialog {
    width: 855px;
    @include media-breakpoint-down(lg){
      width: 90%;
    }
  }


.c-dialog {
  .p-dialog-content {
    padding:unset;
  }
  .dialog-wrapper {
    .p-dialog-content {
      padding:unset !important;
    }
    .c-dialog-content {
      padding:24px;
      max-height: 70vh;
      overflow: auto;
  
      @include media-breakpoint-down(sm){
        max-height: 66vh;
        overflow: auto;
   
      }
      .c-dialog-text {
        @include fontInter(12px, #000, 500);
      }

    

    }
  
    .c-dialog-actions {
      border-top:1px solid #DCDCDC;
      padding-left:24px;
      padding-right: 24px;
      padding-top:12px;
      padding-bottom:12px;
      display:flex;
      align-items: center;
      justify-content: flex-end;
      gap:16px;

      &.chat-footer {
        padding-top:24px;
        padding-bottom:24px;
        gap:16px;
        justify-content: flex-start;
        align-items: flex-start;
        overflow: hidden;
        flex-direction: column;

        .upper-section {
          display: flex;
          gap:8px;
          align-items: flex-start;
        }
      }
    }
  }
}



  .auth-custom-dialog {
    border-radius: 8px;
    background-color: transparent;
    max-width: 90%;
    .p-dialog-content {
      padding:unset !important;
      background-color: transparent;
      border-radius: 8px;
    }
  }


  .p-dialog-header {
    height: 48px;
    background: #F3F3F3;
    .p-dialog-title {
      @include fontInter(14px, #000, 500);
    }

    .p-dialog-header-icon {
      svg {
        path {
          fill:#1C1B1F;
        }
      }
    }
  }


  .add-vehicle-dialog {
    .p-dialog-content {
      padding-top:24px;
      padding-left:30px;
      padding-right: 30px;
      padding-bottom:32px;
  
      @include media-breakpoint-down(md){
        padding-left:16px;
        padding-right: 16px;
        padding-top:16px;
        padding-bottom:16px;
      }
    }
  }
  

