.switch-control-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  margin-bottom:16px;
  label {
    @include fontInter(12px, #000, 500);
  }

  &.small {

      .p-inputswitch {
        width: 1.8rem;
        height: 0.8rem;

        .p-inputswitch-slider {
          &::before {
            width: 0.6rem;
            height: 0.6rem;
            left: 0.2rem;
            margin-top: -0.3rem
          }
        }
      }


      .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
        transform: translateX(0.8rem);
      }
    

  }

  p-inputSwitch {
    .p-inputswitch .p-inputswitch-slider {
       // background-color: #EBEDF5;
    }
    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
        background:#4252A3; 
    }

    
    .p-inputswitch {


      .p-inputswitch-slider {

      }
      .p-inputswitch-slider:before {

      }
    }

  }
}
