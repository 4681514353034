.toast {
    opacity: 1;

    @include media-breakpoint-down(sm) {
        width: 90%;
        left: 5%;
    }

    .p-toast-icon-close {
        position: absolute;
        right: 13px;
        top: 4px;
    }

    .p-toast-message-content {
        padding-top: 10px;
        padding-bottom: 12px;
        padding-right: 38px;
        padding-left: 16px;
    }



    .p-toast-message-custom {
        background-color: #FFF;
        box-shadow: 0px 4px 16px 0px #00000040;
    }

    .p-toast-message-success,
    .p-toast-message-warning,
    .p-toast-message-error,
    .p-toast-message-info {
        .p-toast-icon-close {
            svg {
                path {
                    fill: #FFF;
                }
            }
        }
    }

    .p-toast-message-warning {
        background-color: #E8A701;
        box-shadow: 0px 4px 16px 0px #00000040;
    }

    .p-toast-message-success {
        background-color: #19A540;
        box-shadow: 0px 4px 16px 0px #00000040;
    }


    .p-toast-message-error {
        background-color: #C90F16;
        box-shadow: 0px 4px 16px 0px #00000040;
    }

    .p-toast-message-info {
        background-color: #305DCD;
        box-shadow: 0px 4px 16px 0px #00000040;
    }


}