.c-page-wrapper {

    .c-page-heading {
        padding-bottom: 20px;
        padding-top:20px;
        padding-left:1rem;
        padding-right: 1rem;
        border-bottom: 1px solid #E6E6E6;

        &.tab-heading {
            padding:20px; 
        }
        
        .flex-line {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 16px;

            &.gap-12 {
                gap: 12px;
            }

            &.space-between {
                justify-content: space-between;
            }

            .actions {
                display:flex;
                align-items: center;
                justify-content: flex-end;
                gap:8px;

                @include media-breakpoint-down(sm) {
                    justify-content: flex-start;
                    flex-direction: column;
                    width: 100%;

                    button {
                        width: 100%;
                    }
                }
            }
            
            @include media-breakpoint-down(sm) {
                flex-direction: column;
                align-items: flex-start;
                gap: 8px !important;

                .left, .right {
                    width: 100%;
                }

            }



        }

        h1 {
            @include fontInter(28px, #000, 600, 32px);
        }

        h2 {
            @include fontInter(18px, #000, 500, 24px);
        }

        .c-tab-title {
            @include fontInter(22px, #000, 600, 26px);
        }

        .subheader-text {
            @include fontInter(12px, #000, 400, 20px);


        }

        &.authenticated-page-heading {
            margin-top: 8px;
            margin-bottom: 24px;
        }


        &.lg {
            h1 {
                @include fontInter(36px, #000, 500);
                text-transform: uppercase;
            }
        }

        &.search-heading {

            .heading-text-wrapper {
                display: flex;
                align-items: center;
                height: 100%;
                gap:12px;
                
            }
        }
    }


    .c-page-body-wrapper {
        padding:1rem;

        &.tab-styles {
            background-color: #EFEFEF;
        }
    }

    .grid-user-inner-layout {
        display: grid;
        grid-template-columns: 236px 1fr;
        align-items: start; /* Optional: align items to the start */  
        
        .user-router-wrapper {
            width: 100%;
        }
        .user-inner-menu-wrapper {
            height: 100%;
            border-right: 1px solid #E6E6E6;
        }

        @include media-breakpoint-down(lg){
            display:flex;
            flex-direction: column;
            
            .user-inner-menu-wrapper {
                width: 100%;
                border-right:unset;
                background-color: #EFEFEF !important;
                .user-menu-body {
                    .user-inner-navigation-menu {
                        flex-direction: row !important;
                        flex-wrap: wrap;
                        .menu-link {
                            white-space: nowrap;
                            gap:8px;

                            .left {
                                .prime-icon {
                                    margin-right: 8px !important;
                                }
                            }
                        }
                    }
                }
            }

        }
    } 

    &.text-page {
        .text-content-wrapper {
            h1 {
                @include fontInter(36px, #000, 500);
            }

            h2 {
                @include fontInter(20px, #000, 500);
                margin-bottom: 16px;
            }

            p {
                @include fontInter(14px, #000, 400, 26px);
                margin-bottom: 32px;
            }
        }
    }

    &.lg {
        margin-top: 40px;

        @include media-breakpoint-down(md){
            margin-top:24px;
        }
    }

}

.c-inner-tab-wrapper {
    height: 100%;
    overflow: hidden;

    .c-tab-heading {

        padding: 20px;
        border-bottom: 1px solid #E6E6E6;
        &.heading-with-back-icon {
            .heading-wrapper {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 16px;

                @include media-breakpoint-down(sm) {
                    &.with-order-status {
                        align-items: flex-start;
                    }
                }

                img {
                    cursor: pointer;
                    display: block;
                }
            }

        }

        .flex-line {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 16px;

            .left {
                &.with-arrow-back {
                    display:flex;
                    align-items: center;
                    gap:16px;

                    .arrow-back {
                        cursor: pointer;
                        font-size:22px;
                    }
                }
            }
            &.gap-12 {
                gap: 12px;
            }

            &.space-between {
                justify-content: space-between;
                @include media-breakpoint-down(xl){
                    flex-direction: column;
                    align-items: flex-start;
                    gap:8px;
                }
            }

            .actions {
                display:flex;
                align-items: center;
                justify-content: flex-end;
                gap:8px;

            }

            @include media-breakpoint-down(sm) {
                flex-direction: column;
                align-items: flex-start;
                gap: 8px !important;
            }



        }

        .c-tab-title {
            @include fontInter(22px, #000, 600, 26px);
        }
        

        .subheader-text {
            @include fontInter(12px, #000, 400, 20px);
        }


        &.search-heading {
            border-bottom: 1px solid #DCDCDC;

            @include media-breakpoint-down(md) {
                border-bottom: unset;
            }

            &.border-unset {
                border: unset;
            }

            .heading-text-wrapper {
                display: flex;
                align-items: center;
                height: 100%;
            }
        }
    }



    .c-inner-tab-content {
        background-color: #EFEFEF;
        padding:16px;
        .c-inner-tab-flex-heading {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .c-inner-tab-subheading {
            @include fontInter(16px, #000, 500);
            margin-bottom: 20px;
        }


        .c-inner-tab-text {
            @include fontInter(12px, #000, 400);

            a {
                @include fontInter(12px, #DC2012, 600);
            }
        }


        .c-inner-tab-actions {
            margin-top: 4px;
            display: flex;
            gap: 16px;

            button {
                cursor: pointer;
            }

            .btn-lg {
                width: 224px;
            }

            .btn-md {
                width: 140px;
            }

        }
    }

}

.quick-filters-block {
    width: 100%;
    background-color: #4252a30d;
    padding-left:1.5rem;
    padding-right: 1.5rem;
    padding-top:1rem;
    padding-bottom:1rem;
    border-radius: 4px;
    .quick-filters-header {
        display:flex;
        align-items: center;
        justify-content: space-between;
  
        h4 {
          @include fontInter(12px, #000, 500);
        }
  
        .actions {
          display:flex;
          align-items: center;
          justify-content: flex-end;
          gap:24px;
          a {
            @include fontInter(12px, #676767, 500);
            text-decoration: underline;
            cursor: pointer;
            &.clear-filters {
              color:#4252A3;
            }
          }
        }
      }

    form {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 12px 20px;

        @include media-breakpoint-down(xxl) {
            grid-template-columns: repeat(3, 1fr);
        }

        @include media-breakpoint-down(xl) {
          grid-template-columns: repeat(3, 1fr);
        }

        @include media-breakpoint-down(md) {
          grid-template-columns: repeat(2, 1fr);
        }

        @include media-breakpoint-down(sm) {
          grid-template-columns: repeat(1, 1fr);
        }
    }
}

