.circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    box-shadow: 0px 0px 1px 1px #0000001a;

    &.primary {
        background-color: #4252A3;
    }

    &.pulse {
      animation: pulse-animation 1s infinite;
    }
  }

  
  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(66, 82, 163, 0.4);
    }
    100% {
      box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
    }
  }
  