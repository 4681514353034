.success-error-wrapper {
    .success-error-image {

        &.centered {
            display: block;
            margin:0 auto;
        }
    }

    .success-error-heading {
        @include fontInter(16px, #000, 600);
        &.centered {
            text-align: center;
            display: block;
            width: 100%;
        }
    }

    .success-error-paragraph {
        padding-top:8px;
        padding-bottom:8px;
        padding-left:12px;
        padding-right: 12px;
        max-width: 336px;
        display:block;
        margin:0 auto;
        border-radius: 4px;
        &.success {
            background-color: #19A540;
            @include fontInter(12px, #FFF, 400, 20px);
        }
        &.centered {
            text-align: center;
        }
    }

    .link.centered {
        text-align: center;
        display:block;
        width: 100%;
    }
}