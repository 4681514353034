.status-badge {
    @include fontInter(12px, #000, 500);
    padding: 3px 6px;
    background-color: #f5f5f5;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    &.lg {
        padding: 8px 12px;
    }
    &.email {
        width: 100%;
        justify-content: flex-start;

        .text {
            overflow: hidden;
            text-overflow: ellipsis;  
        }
    }
    &.uppercase {
        text-transform: uppercase;
    }
    .bubble {
     width: 8px;
     height: 8px;
     min-width: 8px;
     border-radius: 50%;
     background-color: #B0B0B0;
     margin-right: 5px;

     
    }

    &.passive {
        .bubble {
            background-color: #B0B0B0;
        }
    }
    &.warning {
        .bubble {
            background-color: #fcd34d;
        }
    }
    &.success {
     .bubble {
         background-color: #4ec63a;
     }
    }

    &.error {
     .bubble {
     background-color: #de5050;
     }   
    }

    &.filled {
     &.error {
         background-color: rgba(222, 80, 80, 0.2);
     }
     &.success {
         background-color: rgba(78, 198, 58, 0.2);
     }
    }
 }