p-password {
    width: 100%;
    display:block;

        .p-password {
            width: 100%;  
        }
        input {
            box-shadow: unset;
            outline:unset;
            padding-right: 8px;
           height: 24px !important;
        } 
    
}