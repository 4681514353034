
    .location-block {
        display: flex;
        gap:6px;
        align-items: center;
        .flag-wrapper {
            display:flex;
            align-items: center;
            .flag {
                width: 20px;
            }
        }

        .location-address {

        }
    }

