

.p-calendar {
  width: 100%;
    &.p-focus {
      border: unset;
      outline: unset;
      box-shadow: unset;
    }
  
  
    // Clear dropdown button
  timesicon {
    background: #71717a;
    position: absolute;
    right: -6px;
    top: -8px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  
  }
  
  svg {
  &.p-calendar-clear-icon {
    color: #FFF;
    border-radius: 50%;
    width: 10px !important;
    height: 10px !important;
    margin-top: unset;
    top:unset;
    right: unset;
    position: unset;
  }
  }
  
  }

.input-calendar-control-wrapper {
    display: flex;
    align-items: center;
    background: #EFEFEF;
    border-radius: 4px;
    height: 40px;
    position: relative;
    margin-bottom:16px;
    border: 1px solid transparent;

    &:hover {
        border: 1px solid #1152F6 !important;
        box-shadow: 0px 0px 8px 0px rgba(27, 105, 223, 0.25) !important;
      }
  
      &.focused {
        border: 1px solid #1152F6 !important;
        box-shadow: 0px 0px 8px 0px rgba(27, 105, 223, 0.25) !important;
      }
  
      &.filled {
        border: 1px solid #1152F6 !important;
        box-shadow: 0px 0px 8px 0px rgba(27, 105, 223, 0.25) !important;
        background-color: #EBEDF5 !important;
    
    
          p-dropdown .p-dropdown {
            background-color: #EBEDF5 !important;
          }
        
      }

      &.disabled {
        opacity: 0.4;
        pointer-events: none;
      }
  
      &.no-label {
        padding-left:12px;
      }

      &.disabled {
        opacity: 0.4;
        pointer-events: none;
      }
  
      &.no-label {
        padding-left:12px;
      }

      label {
        width: 40%;
        min-width: 40%;
        cursor: unset;
        @include fontInter(12px, #6B6B6B, 500);
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 8px;
  
        @include media-breakpoint-down(sm) {
          width: 40%;
          min-width: 40%;
        }
      }


      p-calendar {
        width: 100%;
        input {
            &.disabled {
              opacity: 0.6;
              cursor: pointer;
              pointer-events: none;
            }
            &:not(.p-autocomplete-input) {
              display: flex;
              width: 100%;
              height: 100%;
              border: unset;
              position: unset;
              background-color: unset !important;
              @include fontInter(12px, #000, 600);
              padding-left: unset;
              &::placeholder {
                @include fontInter(12px, #6B6B6B, 500);
              }
            }
      
          }
      }

      &.primary {
        background: #EFEFEF;
  
        label {
          @include fontInter(12px, #6B6B6B, 500);
        }
  
  
  
      }
  
      &.secondary {
        background-color: #FFF;
        border:1px solid rgba(0,0,0,.125);
        label {
          @include fontInter(12px, #6B6B6B, 500);
        }
  
        &.dark-borders {
          border:1px solid #000;
          border-radius: 4px;
        }
      }

      .suffix-icons {
        padding-right: 8px;
        height: 100%;
        display:flex;
        align-items: center;
        justify-content: center;
        overflow: unset;
        .clickable-action {
          cursor: pointer;
        }
      }
}