@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

/* Margin classes */
.m-unset { margin:unset !important;}
.mb-unset { margin-bottom:unset !important;}
.mt-unset { margin-top:unset !important; }
.ml-unset { margin-left:unset !important; }
.mr-unset { margin-right: unset !important}

.mt-2-px { margin-top: 2px !important; }
.mr-2-px { margin-right: 2px !important; }
.mb-2-px { margin-bottom: 2px !important; }
.ml-2-px { margin-left: 2px !important; }

.mt-4-px { margin-top: 4px !important; }
.mr-4-px { margin-right: 4px !important; }
.mb-4-px { margin-bottom: 4px !important; }
.ml-4-px { margin-left: 4px !important; }

.mt-6-px { margin-top: 6px !important; }
.mr-6-px { margin-right: 6px !important; }
.mb-6-px { margin-bottom: 6px !important; }
.ml-6-px { margin-left: 6px !important; }

.mt-8-px { margin-top: 8px !important; }
.mr-8-px { margin-right: 8px !important; }
.mb-8-px { margin-bottom: 8px !important; }
.ml-8-px { margin-left: 8px !important; }

.mt-10-px { margin-top: 10px !important; }
.mr-10-px { margin-right: 10px !important; }
.mb-10-px { margin-bottom: 10px !important; }
.ml-10-px { margin-left: 10px !important; }

.mt-12-px { margin-top: 12px !important; }
.mr-12-px { margin-right: 12px !important; }
.mb-12-px { margin-bottom: 12px !important; }
.ml-12-px { margin-left: 12px !important; }

.mt-14-px { margin-top: 14px !important; }
.mr-14-px { margin-right: 14px !important; }
.mb-14-px { margin-bottom: 14px !important; }
.ml-14-px { margin-left: 14px !important; }

.mt-16-px { margin-top: 16px !important; }
.mr-16-px { margin-right: 16px !important; }
.mb-16-px { margin-bottom: 16px !important; }
.ml-16-px { margin-left: 16px !important; }

.mt-18-px { margin-top: 18px !important; }
.mr-18-px { margin-right: 18px !important; }
.mb-18-px { margin-bottom: 18px !important; }
.ml-18-px { margin-left: 18px !important; }

.mt-20-px { margin-top: 20px !important; }
.mr-20-px { margin-right: 20px !important; }
.mb-20-px { margin-bottom: 20px !important; }
.ml-20-px { margin-left: 20px !important; }

.mt-22-px { margin-top: 22px !important; }
.mr-22-px { margin-right: 22px !important; }
.mb-22-px { margin-bottom: 22px !important; }
.ml-22-px { margin-left: 22px !important; }

.mt-24-px { margin-top: 24px !important; }
.mr-24-px { margin-right: 24px !important; }
.mb-24-px { margin-bottom: 24px !important; }
.ml-24-px { margin-left: 24px !important; }

.mt-26-px { margin-top: 26px !important; }
.mr-26-px { margin-right: 26px !important; }
.mb-26-px { margin-bottom: 26px !important; }
.ml-26-px { margin-left: 26px !important; }

.mt-28-px { margin-top: 28px !important; }
.mr-28-px { margin-right: 28px !important; }
.mb-28-px { margin-bottom: 28px !important; }
.ml-28-px { margin-left: 28px !important; }

.mt-30-px { margin-top: 30px !important; }
.mr-30-px { margin-right: 30px !important; }
.mb-30-px { margin-bottom: 30px !important; }
.ml-30-px { margin-left: 30px !important; }

.mt-32-px { margin-top: 32px !important; }
.mr-32-px { margin-right: 32px !important; }
.mb-32-px { margin-bottom: 32px !important; }
.ml-32-px { margin-left: 32px !important; }

@include media-breakpoint-down(sm) {
    /* Margin classes */

    .m-unset-sm { margin:unset !important;}
    .mb-sm-unset { margin-bottom:unset !important;}
    .mt-sm-unset { margin-top:unset !important; }
    .ml-sm-unset { margin-left:unset !important; }
    .mr-sm-unset { margin-right: unset !important}

    .mt-2-sm-px { margin-top: 2px !important; }
    .mr-2-sm-px { margin-right: 2px !important; }
    .mb-2-sm-px { margin-bottom: 2px !important; }
    .ml-2-sm-px { margin-left: 2px !important; }
  
    .mt-4-sm-px { margin-top: 4px !important; }
    .mr-4-sm-px { margin-right: 4px !important; }
    .mb-4-sm-px { margin-bottom: 4px !important; }
    .ml-4-sm-px { margin-left: 4px !important; }
  
    .mt-6-sm-px { margin-top: 6px !important; }
    .mr-6-sm-px { margin-right: 6px !important; }
    .mb-6-sm-px { margin-bottom: 6px !important; }
    .ml-6-sm-px { margin-left: 6px !important; }
  
    .mt-8-sm-px { margin-top: 8px !important; }
    .mr-8-sm-px { margin-right: 8px !important; }
    .mb-8-sm-px { margin-bottom: 8px !important; }
    .ml-8-sm-px { margin-left: 8px !important; }
  
    .mt-10-sm-px { margin-top: 10px !important; }
    .mr-10-sm-px { margin-right: 10px !important; }
    .mb-10-sm-px { margin-bottom: 10px !important; }
    .ml-10-sm-px { margin-left: 10px !important; }
  
    .mt-12-sm-px { margin-top: 12px !important; }
    .mr-12-sm-px { margin-right: 12px !important; }
    .mb-12-sm-px { margin-bottom: 12px !important; }
    .ml-12-sm-px { margin-left: 12px !important; }
  
    .mt-14-sm-px { margin-top: 14px !important; }
    .mr-14-sm-px { margin-right: 14px !important; }
    .mb-14-sm-px { margin-bottom: 14px !important; }
    .ml-14-sm-px { margin-left: 14px !important; }
  
    .mt-16-sm-px { margin-top: 16px !important; }
    .mr-16-sm-px { margin-right: 16px !important; }
    .mb-16-sm-px { margin-bottom: 16px !important; }
    .ml-16-sm-px { margin-left: 16px !important; }
  
    .mt-18-sm-px { margin-top: 18px !important; }
    .mr-18-sm-px { margin-right: 18px !important; }
    .mb-18-sm-px { margin-bottom: 18px !important; }
    .ml-18-sm-px { margin-left: 18px !important; }
  
    .mt-20-sm-px { margin-top: 20px !important; }
    .mr-20-sm-px { margin-right: 20px !important; }
    .mb-20-sm-px { margin-bottom: 20px !important; }
    .ml-20-sm-px { margin-left: 20px !important; }
  
    .mt-22-sm-px { margin-top: 22px !important; }
    .mr-22-sm-px { margin-right: 22px !important; }
    .mb-22-sm-px { margin-bottom: 22px !important; }
    .ml-22-sm-px { margin-left: 22px !important; }
  
    .mt-24-sm-px { margin-top: 24px !important; }
    .mr-24-sm-px { margin-right: 24px !important; }
    .mb-24-sm-px { margin-bottom: 24px !important; }
    .ml-24-sm-px { margin-left: 24px !important; }
  
    .mt-26-sm-px { margin-top: 26px !important; }
    .mr-26-sm-px { margin-right: 26px !important; }
    .mb-26-sm-px { margin-bottom: 26px !important; }
    .ml-26-sm-px { margin-left: 26px !important; }
  
    .mt-28-sm-px { margin-top: 28px !important; }
    .mr-28-sm-px { margin-right: 28px !important; }
    .mb-28-sm-px { margin-bottom: 28px !important; }
    .ml-28-sm-px { margin-left: 28px !important; }
  
    .mt-30-sm-px { margin-top: 30px !important; }
    .mr-30-sm-px { margin-right: 30px !important; }
    .mb-30-sm-px { margin-bottom: 30px !important; }
    .ml-30-sm-px { margin-left: 30px !important; }
  
    .mt-32-sm-px { margin-top: 32px !important; }
    .mr-32-sm-px { margin-right: 32px !important; }
    .mb-32-sm-px { margin-bottom: 32px !important; }
    .ml-32-sm-px { margin-left: 32px !important; }
  }

  @include media-breakpoint-down(md) {
    /* Margin classes */

    .m-unset-md { margin:unset !important;}
    .mb-md-unset { margin-bottom:unset !important;}
    .mt-md-unset { margin-top:unset !important; }
    .ml-md-unset { margin-left:unset !important; }
    .mr-md-unset { margin-right: unset !important}


    .mt-2-md-px { margin-top: 2px !important; }
    .mr-2-md-px { margin-right: 2px !important; }
    .mb-2-md-px { margin-bottom: 2px !important; }
    .ml-2-md-px { margin-left: 2px !important; }
  
    .mt-4-md-px { margin-top: 4px !important; }
    .mr-4-md-px { margin-right: 4px !important; }
    .mb-4-md-px { margin-bottom: 4px !important; }
    .ml-4-md-px { margin-left: 4px !important; }
  
    .mt-6-md-px { margin-top: 6px !important; }
    .mr-6-md-px { margin-right: 6px !important; }
    .mb-6-md-px { margin-bottom: 6px !important; }
    .ml-6-md-px { margin-left: 6px !important; }
  
    .mt-8-md-px { margin-top: 8px !important; }
    .mr-8-md-px { margin-right: 8px !important; }
    .mb-8-md-px { margin-bottom: 8px !important; }
    .ml-8-md-px { margin-left: 8px !important; }
  
    .mt-10-md-px { margin-top: 10px !important; }
    .mr-10-md-px { margin-right: 10px !important; }
    .mb-10-md-px { margin-bottom: 10px !important; }
    .ml-10-md-px { margin-left: 10px !important; }
  
    .mt-12-md-px { margin-top: 12px !important; }
    .mr-12-md-px { margin-right: 12px !important; }
    .mb-12-md-px { margin-bottom: 12px !important; }
    .ml-12-md-px { margin-left: 12px !important; }
  
    .mt-14-md-px { margin-top: 14px !important; }
    .mr-14-md-px { margin-right: 14px !important; }
    .mb-14-md-px { margin-bottom: 14px !important; }
    .ml-14-md-px { margin-left: 14px !important; }
  
    .mt-16-md-px { margin-top: 16px !important; }
    .mr-16-md-px { margin-right: 16px !important; }
    .mb-16-md-px { margin-bottom: 16px !important; }
    .ml-16-md-px { margin-left: 16px !important; }
  
    .mt-18-md-px { margin-top: 18px !important; }
    .mr-18-md-px { margin-right: 18px !important; }
    .mb-18-md-px { margin-bottom: 18px !important; }
    .ml-18-md-px { margin-left: 18px !important; }
  
    .mt-20-md-px { margin-top: 20px !important; }
    .mr-20-md-px { margin-right: 20px !important; }
    .mb-20-md-px { margin-bottom: 20px !important; }
    .ml-20-md-px { margin-left: 20px !important; }
  
    .mt-22-md-px { margin-top: 22px !important; }
    .mr-22-md-px { margin-right: 22px !important; }
    .mb-22-md-px { margin-bottom: 22px !important; }
    .ml-22-md-px { margin-left: 22px !important; }
  
    .mt-24-md-px { margin-top: 24px !important; }
    .mr-24-md-px { margin-right: 24px !important; }
    .mb-24-md-px { margin-bottom: 24px !important; }
    .ml-24-md-px { margin-left: 24px !important; }
  
    .mt-26-md-px { margin-top: 26px !important; }
    .mr-26-md-px { margin-right: 26px !important; }
    .mb-26-md-px { margin-bottom: 26px !important; }
    .ml-26-md-px { margin-left: 26px !important; }
  
    .mt-28-md-px { margin-top: 28px !important; }
    .mr-28-md-px { margin-right: 28px !important; }
    .mb-28-md-px { margin-bottom: 28px !important; }
    .ml-28-md-px { margin-left: 28px !important; }
  
    .mt-30-md-px { margin-top: 30px !important; }
    .mr-30-md-px { margin-right: 30px !important; }
    .mb-30-md-px { margin-bottom: 30px !important; }
    .ml-30-md-px { margin-left: 30px !important; }
  
    .mt-32-md-px { margin-top: 32px !important; }
    .mr-32-md-px { margin-right: 32px !important; }
    .mb-32-md-px { margin-bottom: 32px !important; }
    .ml-32-md-px { margin-left: 32px !important; }
  }

  @include media-breakpoint-down(lg) {
    /* Margin classes */

    .m-unset-lg { margin:unset !important;}
    .mb-lg-unset { margin-bottom:unset !important;}
    .mt-lg-unset { margin-top:unset !important; }
    .ml-lg-unset { margin-left:unset !important; }
    .mr-lg-unset { margin-right: unset !important}


    .mt-2-lg-px { margin-top: 2px !important; }
    .mr-2-lg-px { margin-right: 2px !important; }
    .mb-2-lg-px { margin-bottom: 2px !important; }
    .ml-2-lg-px { margin-left: 2px !important; }
  
    .mt-4-lg-px { margin-top: 4px !important; }
    .mr-4-lg-px { margin-right: 4px !important; }
    .mb-4-lg-px { margin-bottom: 4px !important; }
    .ml-4-lg-px { margin-left: 4px !important; }
  
    .mt-6-lg-px { margin-top: 6px !important; }
    .mr-6-lg-px { margin-right: 6px !important; }
    .mb-6-lg-px { margin-bottom: 6px !important; }
    .ml-6-lg-px { margin-left: 6px !important; }
  
    .mt-8-lg-px { margin-top: 8px !important; }
    .mr-8-lg-px { margin-right: 8px !important; }
    .mb-8-lg-px { margin-bottom: 8px !important; }
    .ml-8-lg-px { margin-left: 8px !important; }
  
    .mt-10-lg-px { margin-top: 10px !important; }
    .mr-10-lg-px { margin-right: 10px !important; }
    .mb-10-lg-px { margin-bottom: 10px !important; }
    .ml-10-lg-px { margin-left: 10px !important; }
  
    .mt-12-lg-px { margin-top: 12px !important; }
    .mr-12-lg-px { margin-right: 12px !important; }
    .mb-12-lg-px { margin-bottom: 12px !important; }
    .ml-12-lg-px { margin-left: 12px !important; }
  
    .mt-14-lg-px { margin-top: 14px !important; }
    .mr-14-lg-px { margin-right: 14px !important; }
    .mb-14-lg-px { margin-bottom: 14px !important; }
    .ml-14-lg-px { margin-left: 14px !important; }
  
    .mt-16-lg-px { margin-top: 16px !important; }
    .mr-16-lg-px { margin-right: 16px !important; }
    .mb-16-lg-px { margin-bottom: 16px !important; }
    .ml-16-lg-px { margin-left: 16px !important; }
  
    .mt-18-lg-px { margin-top: 18px !important; }
    .mr-18-lg-px { margin-right: 18px !important; }
    .mb-18-lg-px { margin-bottom: 18px !important; }
    .ml-18-lg-px { margin-left: 18px !important; }
  
    .mt-20-lg-px { margin-top: 20px !important; }
    .mr-20-lg-px { margin-right: 20px !important; }
    .mb-20-lg-px { margin-bottom: 20px !important; }
    .ml-20-lg-px { margin-left: 20px !important; }
  
    .mt-22-lg-px { margin-top: 22px !important; }
    .mr-22-lg-px { margin-right: 22px !important; }
    .mb-22-lg-px { margin-bottom: 22px !important; }
    .ml-22-lg-px { margin-left: 22px !important; }
  
    .mt-24-lg-px { margin-top: 24px !important; }
    .mr-24-lg-px { margin-right: 24px !important; }
    .mb-24-lg-px { margin-bottom: 24px !important; }
    .ml-24-lg-px { margin-left: 24px !important; }
  
    .mt-26-lg-px { margin-top: 26px !important; }
    .mr-26-lg-px { margin-right: 26px !important; }
    .mb-26-lg-px { margin-bottom: 26px !important; }
    .ml-26-lg-px { margin-left: 26px !important; }
  
    .mt-28-lg-px { margin-top: 28px !important; }
    .mr-28-lg-px { margin-right: 28px !important; }
    .mb-28-lg-px { margin-bottom: 28px !important; }
    .ml-28-lg-px { margin-left: 28px !important; }
  
    .mt-30-lg-px { margin-top: 30px !important; }
    .mr-30-lg-px { margin-right: 30px !important; }
    .mb-30-lg-px { margin-bottom: 30px !important; }
    .ml-30-lg-px { margin-left: 30px !important; }
  
    .mt-32-lg-px { margin-top: 32px !important; }
    .mr-32-lg-px { margin-right: 32px !important; }
    .mb-32-lg-px { margin-bottom: 32px !important; }
    .ml-32-lg-px { margin-left: 32px !important; }
  }
  @include media-breakpoint-down(xl) {
    /* Margin classes */

    .m-unset-xl { margin:unset !important;}
    .mb-xl-unset { margin-bottom:unset !important;}
    .mt-xl-unset { margin-top:unset !important; }
    .ml-xl-unset { margin-left:unset !important; }
    .mr-xl-unset { margin-right: unset !important}


    .mt-2-xl-px { margin-top: 2px !important; }
    .mr-2-xl-px { margin-right: 2px !important; }
    .mb-2-xl-px { margin-bottom: 2px !important; }
    .ml-2-xl-px { margin-left: 2px !important; }
  
    .mt-4-xl-px { margin-top: 4px !important; }
    .mr-4-xl-px { margin-right: 4px !important; }
    .mb-4-xl-px { margin-bottom: 4px !important; }
    .ml-4-xl-px { margin-left: 4px !important; }
  
    .mt-6-xl-px { margin-top: 6px !important; }
    .mr-6-xl-px { margin-right: 6px !important; }
    .mb-6-xl-px { margin-bottom: 6px !important; }
    .ml-6-xl-px { margin-left: 6px !important; }
  
    .mt-8-xl-px { margin-top: 8px !important; }
    .mr-8-xl-px { margin-right: 8px !important; }
    .mb-8-xl-px { margin-bottom: 8px !important; }
    .ml-8-xl-px { margin-left: 8px !important; }
  
    .mt-10-xl-px { margin-top: 10px !important; }
    .mr-10-xl-px { margin-right: 10px !important; }
    .mb-10-xl-px { margin-bottom: 10px !important; }
    .ml-10-xl-px { margin-left: 10px !important; }
  
    .mt-12-xl-px { margin-top: 12px !important; }
    .mr-12-xl-px { margin-right: 12px !important; }
    .mb-12-xl-px { margin-bottom: 12px !important; }
    .ml-12-xl-px { margin-left: 12px !important; }
  
    .mt-14-xl-px { margin-top: 14px !important; }
    .mr-14-xl-px { margin-right: 14px !important; }
    .mb-14-xl-px { margin-bottom: 14px !important; }
    .ml-14-xl-px { margin-left: 14px !important; }
  
    .mt-16-xl-px { margin-top: 16px !important; }
    .mr-16-xl-px { margin-right: 16px !important; }
    .mb-16-xl-px { margin-bottom: 16px !important; }
    .ml-16-xl-px { margin-left: 16px !important; }
  
    .mt-18-xl-px { margin-top: 18px !important; }
    .mr-18-xl-px { margin-right: 18px !important; }
    .mb-18-xl-px { margin-bottom: 18px !important; }
    .ml-18-xl-px { margin-left: 18px !important; }
  
    .mt-20-xl-px { margin-top: 20px !important; }
    .mr-20-xl-px { margin-right: 20px !important; }
    .mb-20-xl-px { margin-bottom: 20px !important; }
    .ml-20-xl-px { margin-left: 20px !important; }
  
    .mt-22-xl-px { margin-top: 22px !important; }
    .mr-22-xl-px { margin-right: 22px !important; }
    .mb-22-xl-px { margin-bottom: 22px !important; }
    .ml-22-xl-px { margin-left: 22px !important; }
  
    .mt-24-xl-px { margin-top: 24px !important; }
    .mr-24-xl-px { margin-right: 24px !important; }
    .mb-24-xl-px { margin-bottom: 24px !important; }
    .ml-24-xl-px { margin-left: 24px !important; }
  
    .mt-26-xl-px { margin-top: 26px !important; }
    .mr-26-xl-px { margin-right: 26px !important; }
    .mb-26-xl-px { margin-bottom: 26px !important; }
    .ml-26-xl-px { margin-left: 26px !important; }
  
    .mt-28-xl-px { margin-top: 28px !important; }
    .mr-28-xl-px { margin-right: 28px !important; }
    .mb-28-xl-px { margin-bottom: 28px !important; }
    .ml-28-xl-px { margin-left: 28px !important; }
  
    .mt-30-xl-px { margin-top: 30px !important; }
    .mr-30-xl-px { margin-right: 30px !important; }
    .mb-30-xl-px { margin-bottom: 30px !important; }
    .ml-30-xl-px { margin-left: 30px !important; }
  
    .mt-32-xl-px { margin-top: 32px !important; }
    .mr-32-xl-px { margin-right: 32px !important; }
    .mb-32-xl-px { margin-bottom: 32px !important; }
    .ml-32-xl-px { margin-left: 32px !important; }
  }

  @include media-breakpoint-down(xxl) {
    /* Margin classes */

    .m-unset-xxl { margin:unset !important;}
    .mb-xxl-unset { margin-bottom:unset !important;}
    .mt-xxl-unset { margin-top:unset !important; }
    .ml-xxl-unset { margin-left:unset !important; }
    .mr-xxl-unset { margin-right: unset !important}


    .mt-2-xxl-px { margin-top: 2px !important; }
    .mr-2-xxl-px { margin-right: 2px !important; }
    .mb-2-xxl-px { margin-bottom: 2px !important; }
    .ml-2-xxl-px { margin-left: 2px !important; }
  
    .mt-4-xxl-px { margin-top: 4px !important; }
    .mr-4-xxl-px { margin-right: 4px !important; }
    .mb-4-xxl-px { margin-bottom: 4px !important; }
    .ml-4-xxl-px { margin-left: 4px !important; }
  
    .mt-6-xxl-px { margin-top: 6px !important; }
    .mr-6-xxl-px { margin-right: 6px !important; }
    .mb-6-xxl-px { margin-bottom: 6px !important; }
    .ml-6-xxl-px { margin-left: 6px !important; }
  
    .mt-8-xxl-px { margin-top: 8px !important; }
    .mr-8-xxl-px { margin-right: 8px !important; }
    .mb-8-xxl-px { margin-bottom: 8px !important; }
    .ml-8-xxl-px { margin-left: 8px !important; }
  
    .mt-10-xxl-px { margin-top: 10px !important; }
    .mr-10-xxl-px { margin-right: 10px !important; }
    .mb-10-xxl-px { margin-bottom: 10px !important; }
    .ml-10-xxl-px { margin-left: 10px !important; }
  
    .mt-12-xxl-px { margin-top: 12px !important; }
    .mr-12-xxl-px { margin-right: 12px !important; }
    .mb-12-xxl-px { margin-bottom: 12px !important; }
    .ml-12-xxl-px { margin-left: 12px !important; }
  
    .mt-14-xxl-px { margin-top: 14px !important; }
    .mr-14-xxl-px { margin-right: 14px !important; }
    .mb-14-xxl-px { margin-bottom: 14px !important; }
    .ml-14-xxl-px { margin-left: 14px !important; }
  
    .mt-16-xxl-px { margin-top: 16px !important; }
    .mr-16-xxl-px { margin-right: 16px !important; }
    .mb-16-xxl-px { margin-bottom: 16px !important; }
    .ml-16-xxl-px { margin-left: 16px !important; }
  
    .mt-18-xxl-px { margin-top: 18px !important; }
    .mr-18-xxl-px { margin-right: 18px !important; }
    .mb-18-xxl-px { margin-bottom: 18px !important; }
    .ml-18-xxl-px { margin-left: 18px !important; }
  
    .mt-20-xxl-px { margin-top: 20px !important; }
    .mr-20-xxl-px { margin-right: 20px !important; }
    .mb-20-xxl-px { margin-bottom: 20px !important; }
    .ml-20-xxl-px { margin-left: 20px !important; }
  
    .mt-22-xxl-px { margin-top: 22px !important; }
    .mr-22-xxl-px { margin-right: 22px !important; }
    .mb-22-xxl-px { margin-bottom: 22px !important; }
    .ml-22-xxl-px { margin-left: 22px !important; }
  
    .mt-24-xxl-px { margin-top: 24px !important; }
    .mr-24-xxl-px { margin-right: 24px !important; }
    .mb-24-xxl-px { margin-bottom: 24px !important; }
    .ml-24-xxl-px { margin-left: 24px !important; }
  
    .mt-26-xxl-px { margin-top: 26px !important; }
    .mr-26-xxl-px { margin-right: 26px !important; }
    .mb-26-xxl-px { margin-bottom: 26px !important; }
    .ml-26-xxl-px { margin-left: 26px !important; }
  
    .mt-28-xxl-px { margin-top: 28px !important; }
    .mr-28-xxl-px { margin-right: 28px !important; }
    .mb-28-xxl-px { margin-bottom: 28px !important; }
    .ml-28-xxl-px { margin-left: 28px !important; }
  
    .mt-30-xxl-px { margin-top: 30px !important; }
    .mr-30-xxl-px { margin-right: 30px !important; }
    .mb-30-xxl-px { margin-bottom: 30px !important; }
    .ml-30-xxl-px { margin-left: 30px !important; }
  
    .mt-32-xxl-px { margin-top: 32px !important; }
    .mr-32-xxl-px { margin-right: 32px !important; }
    .mb-32-xxl-px { margin-bottom: 32px !important; }
    .ml-32-xxl-px { margin-left: 32px !important; }
  }

