.inline-autocomplete-label-control {
        display: flex;
        align-items: center;
        background: #EFEFEF;
        border-radius: 4px;
        min-height: 40px;
        position: relative;
        margin-bottom:16px;
        position:relative;
        border: 1px solid transparent;
        padding-top: 12px;
        padding-bottom:12px;
        &:hover {
          border: 1px solid #1152F6 !important;
          box-shadow: 0px 0px 8px 0px rgba(27, 105, 223, 0.25) !important;
        }
        
        &:focus {
            border: 1px solid #1152F6 !important;
            box-shadow: 0px 0px 8px 0px rgba(27, 105, 223, 0.25) !important;
        }
        &.focused {
          border: 1px solid #1152F6 !important;
          box-shadow: 0px 0px 8px 0px rgba(27, 105, 223, 0.25) !important;
        }
    
        &.filled {
          border: 1px solid #1152F6 !important;
          box-shadow: 0px 0px 8px 0px rgba(27, 105, 223, 0.25) !important;
          background-color: #EBEDF5 !important;
      
      
            p-dropdown .p-dropdown {
              background-color: #EBEDF5 !important;
            }
          
        }
    
        &.locked {
          label {
            cursor: default;
          }
          .p-dropdown {
            .p-dropdown-trigger {
              display:none;
            }
          }
        }
        .suffix-icons {
          padding-right: 8px;
          height: 100%;
          display:flex;
          align-items: center;
          justify-content: center;
          overflow: unset;
          .clickable-action {
            cursor: pointer;
          }
        }
    
        &.disabled {
          opacity: 0.4;
          pointer-events: none;
        }
    
        &.no-label {
          padding-left:12px;
        }
        label {
          width: 40%;
          min-width: 40%;
    
    
          @include media-breakpoint-down(sm){
            width: 40%;
            min-width: 40%;
          }
          @include fontInter(12px, #6B6B6B, 500);
          cursor: pointer;
          height: 100%;
          display: flex;
          align-items: center;
          padding-left: 8px;
    
        }
    
    
        p-autocomplete {
            width: 100%;
        
            &.inline-label-autocomplete {
                .p-autocomplete {
                    min-height: 37px;
                }
            }
        
        
                .p-autocomplete {
                    width: 100%;
                    border:unset;
                    border-radius: 2px;
                    min-height: 37px;
                  
        
                    .p-autocomplete-multiple-container {
                        background-color: transparent;
                        border:unset !important;
                        outline:unset !important;
                        border-top-left-radius: 2px;
                        border-bottom-left-radius: 2px;
                        padding-left:unset;
                        @include fontInter(12px, #000, 500);
                        width: 100%; 
                      
                        .p-autocomplete-token {
                          background-color: #000;
                          @include fontInter(12px, #FFF, 500);
                        }
                        &:hover {
                            border:unset;
                            box-shadow: unset;
                            outline:unset;
                          }
                      
                          &.focused {
                            border:unset;
                            box-shadow: unset;
                            outline:unset;
                          }
        
                          &:focus {
                            border:unset;
                            box-shadow: unset;
                            outline:unset;
                          }
                    }
        
                    input {
                        background-color: transparent;
                        border:unset;
                        border-top-left-radius: 2px;
                        border-bottom-left-radius: 2px;
                        padding-left:unset;
                        @include fontInter(12px, #000, 500);
                        width: 100%;
                        &::placeholder {
                            @include fontInter(12px, #828282, 500);  
                        }
                    }
                           
        
        
                    .p-autocomplete-dropdown {
                        background-color: unset;
                        border:unset;
                        border-top-right-radius: 2px;
                        border-bottom-right-radius: 2px;
                         
                            width: 17px;
                            margin-right: 12px;
                            svg {
                              width: 12px;
                            }
        
                            path {
                                fill:#1C1B1F;
                            }
                        
                    }
                }
            
        }
      
      
        &.sm {
          height: 36px;
        }
        
        &.primary {
          background: #EFEFEF;
      
          label {
            @include fontInter(12px, #6B6B6B, 500);
          }
      
          p-dropdown {
            .p-dropdown {
              background: #EFEFEF;
            }
      
            .p-dropdown-label {
              @include fontInter(12px, #000, 500);
            }
      
            .p-dropdown-trigger {
              color: #000;
            }
          }
      
          
        }
        
        &.outline {
          background-color: #FFF;
          border:1px solid #EFEFEF;
          label {
            background-color: transparent;
          }
    
          p-dropdown {
            .p-dropdown {
              background: #FFF;
            }
          }
        }
    
    
        &.secondary {
          background-color: #FFF;
          border:1px solid rgba(0,0,0,.125);
          label {
            @include fontInter(12px, #6B6B6B, 500);
          }
      
          p-dropdown {
            .p-dropdown {
              background: #FFF;
            }
      
            .p-dropdown-label {
              @include fontInter(12px, #000, 500);
            }
      
            .p-dropdown-trigger {
              color: #000;
            }
          }
        }
    
    
          &.dark-borders {
              border:1px solid #000 !important;
              border-radius: 4px;

              &:focus {
                border: 1px solid #1152F6 !important;
                box-shadow: 0px 0px 8px 0px rgba(27, 105, 223, 0.25) !important;
            }

            &:hover {
                border: 1px solid #1152F6 !important;
                box-shadow: 0px 0px 8px 0px rgba(27, 105, 223, 0.25) !important;
              }
              
          }
          
        
      
      }
    
