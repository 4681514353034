.loader-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-top:42px;
    padding-bottom:42px;

    &.pending-loader {
        width: 152px;
        height: 140px;
        margin:0 auto;
        margin-bottom: 24px;
        margin-top: 50px;
    }

}

.c-loader {
    width: 4rem;
    height: 4rem;


    &.medium {
        width: 2rem;
        height: 2rem;   
    }

    &.btn-size {
        width: 1.4rem;
        height: 1.4rem;  
    }

    &.small {
        width: 1rem;
        height: 1rem;
    }

    &.centered-vertically {
        height: 100%;
    }

    &.light {
        .p-progress-spinner-circle {
            stroke: #FFF;
            animation:unset;
        }
    }


}