/* You can add global styles to this file, and also import other style files */

@import "./../node_modules/primeicons/primeicons.css";
@import './../node_modules/bootstrap/scss/bootstrap-grid';
@import 'base/fonts';  
@import 'abstracts/mixins';    
@import 'components/components';
@import 'classess/classess';
@import 'base/layout';


html, body {
    margin: unset;
}

h1,h2,h3,h4,h5,h6, p, a {
    margin-top:unset;
}



.icon-not-visible-breadcrumb {
  .p-icon-wrapper {
    display:none;
  }
}

.text-not-visible-breadcrumb {
  .p-menuitem-text {
    display:none;
  }
}

button {
    outline: 0 none;
    outline-offset: 0px;
    box-shadow: none;
}


  input {
    outline: 0 none;
    outline-offset: 0px;
    box-shadow: none;
  }


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
