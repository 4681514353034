p-slider {

    .p-slider-horizontal {
        background-color: #D7D7D7;
        border-radius: 4px;
    }
    .p-slider-range {
        background-color: #4252A3;
        border-radius: 4px;
    }

    .p-slider-handle {
        border:1px solid #D7D7D7;
    }

    .p-slider-handle:hover {
        border:1px solid #D7D7D7 !important;
        background-color: #FFF;
        box-shadow: unset;
        outline: unset;
    }

    .p-slider-handle-active {
        border:1px solid #D7D7D7 !important;
        background-color: #FFF;
        box-shadow: unset;
        outline: unset;
    }

}