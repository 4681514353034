@import './margins';
@import './responsive';
@import './paddings';

// gutters
.gx-custom-40-up-sm {
  @include media-breakpoint-up(sm) {
    --bs-gutter-x: 2.5rem !important;
  }
}

.gx-custom-lg-unset {
  @include media-breakpoint-down(lg) {
    --bs-gutter-x: 0 !important;
  }
}


.gx-custom-md-unset {
  @include media-breakpoint-down(md) {
    --bs-gutter-x: 0 !important;
  }
}


.gx-custom-sm-unset {
  @include media-breakpoint-down(sm) {
    --bs-gutter-x: 0 !important;
  }
}


// width and height 
.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}


// links 
.link {
  @include fontInter(12px, #4252A3, 600, 20px);
  text-decoration: underline;
  cursor: pointer;

  &.primary {
    color: #000;
  }

  &.danger {
    color: #4252A3 !important;
  }

  &.weight-400 {
    font-weight: 400 !important;
  }

  &.weight-500 {
    font-weight: 500 !important;
  }


  &.weight-600 {
    font-weight: 600 !important;
  }

  &.weight-700 {
    font-weight: 700 !important;
  }

  &.lg {
    font-size: 14px !important;
  }

  &.inline-block {
    display: inline-block;
  }

  &.flex-link {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }


}

.c-scrollbar {
  .p-scrollpanel-bar {
    background-color: #DCDCDC;
  }

  &.dark {
    .p-scrollpanel-bar {
      background-color: #AEAEAE;
    }
  }
}

.capitalize {
  text-transform: capitalize;
}

.no-decoration {
  text-decoration: unset !important;
}


.no-results-found-block {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 16px;
  padding-right: 16px;

  img {
    margin-bottom: 8px;
    height: 100px;
  }

  h3 {
    @include fontInter(16px, #000, 500, 20px);
    margin-bottom: 4px;
    text-align: center;
  }

  p {
    @include fontInter(12px, #676767, 400, 18px);
    margin-bottom: unset;
    text-align: center;
  }

  &.small {}
}

.lg-backdrop {
  background: rgba(0, 0, 0, 0.8);
}

.lg-thumb-outer {
  background: transparent;
  background-color:transparent !important;
}

.overflow-visible {
  overflow: visible;
}
.hidden-images-array {
  opacity: 0;
  height: 0;
  position: absolute;
  display: none;
  //  pointer-events: none;
}

/* Keyframes for pulse animation */
@keyframes pulsePurple {
  0% {
    //  transform: scale(1);
    box-shadow: 0 0 0 0 rgba(66, 82, 163, 0.7);
  }

  70% {
    //  transform: scale(1.1);
    box-shadow: 0 0 10px 10px rgba(66, 82, 163, 0);
  }

  100% {
    // transform: scale(1);
    box-shadow: 0 0 0 0 rgba(66, 82, 163, 0);
  }
}

@keyframes pulseGreen {
    0% {
      //  transform: scale(1);
      box-shadow: 0 0 0 0 rgba(0, 128, 0, 0.7); /* Green color */
    }
  
    70% {
      //  transform: scale(1.1);
      box-shadow: 0 0 10px 10px rgba(0, 128, 0, 0); /* Green color */
    }
  
    100% {
      //  transform: scale(1);
      box-shadow: 0 0 0 0 rgba(0, 128, 0, 0); /* Green color */
    }
  }

  
  @keyframes pulseRed {
    0% {
      box-shadow: 0 0 0 0 rgba(222, 80, 80, 0.7);
    }
  
    70% {
      box-shadow: 0 0 10px 10px rgba(222, 80, 80, 0);
    }
  
    100% {
      box-shadow: 0 0 0 0 rgba(222, 80, 80, 0);
    }
  }


  @keyframes pulseYellow {
    0% {
      box-shadow: 0 0 0 0 rgba(252, 211, 77, 0.7);
    }
  
    70% {
      box-shadow: 0 0 10px 10px rgba(252, 211, 77, 0);
    }
  
    100% {
      box-shadow: 0 0 0 0 rgba(252, 211, 77, 0);
    }
  }

