p-autocomplete {
    width: 100%;

    &.inline-label-autocomplete {
        .p-autocomplete {
            height: 37px;
        }
    }


        .p-autocomplete {
            width: 100%;
            background:#EFEFEF;
            border:unset;
            border-radius: 2px;
            height: 40px;



            input {
                background-color: transparent;
                border:unset;
                border-top-left-radius: 2px;
                border-bottom-left-radius: 2px;
                padding-left:unset;
                @include fontInter(12px, #000, 500);
                width: 100%;
                &::placeholder {
                    @include fontInter(12px, #828282, 500);  
                }
            }
                   


            .p-autocomplete-dropdown {
                background-color: unset;
                border:unset;
                border-top-right-radius: 2px;
                border-bottom-right-radius: 2px;
                 
                    width: 14px;
                    margin-right: 12px;
                    svg {
                      width: 12px;
                    }

                    path {
                        fill:#1C1B1F;
                    }
                
            }
        }
    
}