.table-actions {
    display:flex;
    align-items: center;
    justify-content: flex-start;
    gap:16px;
    margin-bottom:16px;
    width: 100%;
    @include media-breakpoint-down(md){
        flex-direction: column;
    }
    .left{
        display: flex;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        margin-right: auto;
        width: 50%;

        @include media-breakpoint-down(md){
            width: 100%;
        }

    }

    .right {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap:16px;
        margin-left:auto;
       // overflow-x: scroll;

       @include media-breakpoint-down(md){
        flex-direction: column;
        width: 100%;
       }
       .grid-item {
        min-width: 210px;
        @include media-breakpoint-down(md){
         min-width: unset;
         width: 100%;
        }
       }

    }
}
.table-outer-wrapper {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .08);
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .4375rem;
    background-clip: border-box;
    background-color: #fff;

    .p-tabview-panels {
        padding:unset;
    }
}
.table-wrapper {
    p-table {

        &.selectable-rows {
            tbody {
                tr {
                    &:hover {
                        cursor: pointer;
                        background: rgba(66, 82, 163, 0.03) !important;
                    }
                }
            }
        }

        &.hoverable-rows {
            tbody {
                tr {
                    &:hover {
                        background: rgba(66, 82, 163, 0.03) !important;
                    }
                }
            }
        }



        table.p-datatable-table {
            min-width: unset !important;
        }

        .p-datatable-loading-overlay {
            background-color: rgba(0, 0, 0, 0.1);
        }



        .p-datatable-header {
           // background-color: rgba(66, 82, 163, 0.0509803922);
            background-color: rgba(239, 239, 239, 0.3);
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            padding-top: 1rem;
            padding-bottom: 1rem;
          
            border-top-left-radius: .4375rem;
            border-top-right-radius: .4375rem;

            .table-header {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;
                gap: 16px;
                .upper-row {
                    width: 100%;
                    display: grid;
                    grid-template-columns: repeat(5, 1fr);
                    /* Four items per row */
                    gap: 12px 20px;
                    /* Row gap of 12px and column gap of 20px */
                    @include media-breakpoint-down(xxl) {
                        grid-template-columns: repeat(4, 1fr);
                      }


                    @include media-breakpoint-down(xl) {
                      grid-template-columns: repeat(3, 1fr);
                    }
          
                    @include media-breakpoint-down(md) {
                      grid-template-columns: repeat(2, 1fr);
                    }
          
                    @include media-breakpoint-down(sm) {
                      grid-template-columns: repeat(1, 1fr);
                    }

                    h1 {
                        @include fontInter(22px, #000, 600);
                        margin: unset;
                    }


                }



                app-search-input {
                    max-width: 300px;
                }
            }
        }

        thead {
           // background: #EFEFEF;
            tr.table-labels-row {
                height: 48px;

                th {

                    padding: unset;
                    padding-left: 8px;
                    background-color: transparent;
                    border-bottom: 1px solid #DCDCDC;

                    &:first-of-type {
                        padding-left: 1.5rem;
                    }

                    .default-table-label {
                        @include fontInter(14px, #646464, 400);

                        &.with-bubble {
                            display:inline-flex;
                            align-items: center;
                            justify-content: flex-start;
                            .bubble {
                                width: 8px;
                                height: 8px;
                                min-width: 8px;
                                border-radius: 50%;
                                background-color: #000;
                                margin-right: 5px;
                                position: relative;
                                margin-top: 1px;
                                &.passive {
                            
                                        background-color: #B0B0B0;
                                    
                                }
                                &.warning {
                               
                                        background-color: #fcd34d;
                                    
                                }
                                &.success {
                          
                                     background-color: #4ec63a;
                                 
                                }
                            
                                &.error {
                           
                                 background-color: #de5050;
                                   
                                }
                               }
                        }

                    }

                    .p-sortable-column-icon {
                        color: #4252A3;
                    }

                    &.p-highlight {
                      //  background-color: rgba(66, 82, 163, 0.1);

                        .default-table-label {
                            @include fontInter(14px, #4252A3, 700);
                        }
                    }

                    p-sortIcon {
                        svg {
                            width: 10px;
                        }
                    }

                }
            }

            tr.table-filters-row {
                th {

                    padding: unset;
                    padding-left: 8px;
                    padding-top:12px;
                    padding-bottom:12px;
                    border-bottom: 1px solid #DCDCDC;
                    background: #FFF;

                    &:first-of-type {
                        padding-left: 1.5rem;
                    }

                    .default-table-label {
                        @include fontInter(14px, #646464, 400);
                    }






                }
            }
        }

        tbody {
            tr {

                &.completed {
                    
                }
                td {
                    padding-left: 8px;
                    padding-right: 8px;
                    padding-top: 16px;
                    padding-bottom: 16px;
                    border-color: #DCDCDC;
                    @include fontInter(14px, #000, 400);

                    &.expanded-row {
                        background:rgba(66, 82, 163, 0.03) !important
                    }

                    &:first-of-type {
                        padding-left: 1.5rem;
                    }

                    &.name-table-column {
                        max-width: 360px; 
                    }
                
                    &.md-table-column {
                        max-width: 260px;
                    }

                    &.actions-table-column {
                        max-width: 120px;  
                        .table-action-btn {
                            width: 35px;
                            height: 35px;
                            cursor: pointer;

                            border-radius: 50%;

                            i {
                                color: #000;


                            } 
                        }

                        @include media-breakpoint-down(xxl) {
                            max-width: 40px;
                            width: 40px;

                            .table-action-btn {
                                margin-left: unset;
                            }
                        }
                    }
            
                    a {
                        max-width: 200px;
                       // white-space: normal;
                       // word-break: break-all;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }




                }
            }
        }

    }



    &.small-table {
        overflow: auto;
        p-table p-datatable .p-datatable-wrapper table {
            min-width: unset !important;
        }
        thead {
             tr.table-labels-row {
                 height: 36px;
 
                 th {
 
                     padding: unset;
                     padding-left: 8px;
 
                     &:first-of-type {
                         padding-left: 10px;
                     }
 
                     .default-table-label {
                         @include fontInter(13px, #646464, 400);
                     }
 
                     .p-sortable-column-icon {
                         color: #4252A3;
                     }
 
                     &.p-highlight {
                       //  background-color: rgba(66, 82, 163, 0.1);
 
                         .default-table-label {
                             @include fontInter(13px, #4252A3, 700);
                         }
                     }
 
                     p-sortIcon {
                         svg {
                             width: 10px;
                         }
                     }
 
                 }
             }

         }
 
         tbody {
             tr {
                 td {
                     @include fontInter(13px, #000, 400);
                    padding-top:5px;
                    padding-bottom:5px;
                     &:first-of-type {
                         padding-left: 10px;
                     }
 
                     &.name-table-column {
                         max-width: 360px; 
                     }
                     
                     a {
                        font-size:12px !important;
                     }
 
                     &.actions-table-column {
                         max-width: 120px;  
                         .table-action-btn {
                             width: 35px;
                             height: 35px;
                             cursor: pointer;
 
                             border-radius: 50%;
 
                             i {
                                 color: #000;
 
 
                             } 
                         }
 
                         @include media-breakpoint-down(xxl) {
                             max-width: 40px;
                             width: 40px;
 
                             .table-action-btn {
                                 margin-left: unset;
                             }
                         }
                     }
             
                     a {
                         max-width: 200px;
                        // white-space: normal;
                        // word-break: break-all;
                         overflow: hidden;
                         text-overflow: ellipsis;
                     }
 
 
 
 
                 }
             }
         }

    }   

    .table-loading-wrapper {
        display:flex;
        align-items: center;
        justify-content: center;
        padding-top:40px;
        padding-bottom:40px;
        padding-left:16px;
        padding-right: 16px;
    }

    .table-no-results-found {
        display:flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        padding-top:40px;
        padding-bottom:40px;
        padding-left:16px;
        padding-right: 16px;
        img {
            margin-bottom:8px;
            height: 100px;
        }

        h3 {
            @include fontInter(16px, #000, 500, 20px);
            margin-bottom:4px;
            text-align: center;
        }

        p {
            @include fontInter(12px, #676767, 400, 18px);
            margin-bottom:unset;
            text-align: center;
        }
    }
}