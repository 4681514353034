.search-wrapper {
  width: 100%;
  position: relative;

  &.disabled {
    opacity: 0.6;
  }
  .search-input {
      width: 100%;

      &.clearable {
        input {
          padding-right: 34px;
        }
        .search-input-clear-icon {
          position: absolute;
          right: 8px;
          top: 50%;
          margin-top: -10px;
          cursor: pointer;
        }
      }
      i {
          color: #6B6B6B;
       
        }

        svg {
          position: absolute;
          top: 50%;
          margin-top: -10px;
          margin-left: 12px;
        }

        input {
          width: 100%;
          border-radius: 4px;
          background: #EFEFEF;
          height: 40px;
          @include fontInter(12px, #000, 500);
          border: unset;
          border:1px solid transparent;
          
          &:hover {
            border: 1px solid #1152F6 !important;
            box-shadow: 0px 0px 8px 0px rgba(27, 105, 223, 0.25) !important;
          }
      
          &.focused {
            border: 1px solid #1152F6 !important;
            box-shadow: 0px 0px 8px 0px rgba(27, 105, 223, 0.25) !important;
          }
    
          &::placeholder {
            @include fontInter(12px, #6B6B6B, 500);
  
          }
        }
  }

  &.outline {
    border:1px solid #EFEFEF;
    border-radius: 4px;
    i {
      color:#1C1B1F;
    }

    svg {
      path {
        fill: #1C1B1F;
      }
    }

    input {
      background-color: #FFF;
    }
  }

  &.secondary {
      i {
        color:#1C1B1F;
      }

      svg {
        path {
          fill: #1C1B1F;
        }
      }

      input {
        border:1px solid rgba(0,0,0,.125);
        border-radius: 4px;
        background-color: #FFF;

        
        
      }
  }

  &.secondary-md {
    @include media-breakpoint-down(md){

      .search-input {

        i {
          color: #1C1B1F;
        }


        svg {
          path {
            fill: #1C1B1F;
          }
        }
        input {
          background-color: #FFF;
          height: 56px;
          border-radius: 4px;
          border:1px solid #DCDCDC;


          &::placeholder {
            @include fontInter(12px, #737373, 500);
  
          }

          
        }
      }

    }

    @include media-breakpoint-down(sm){
      .search-input {
        input {
          border-radius: unset;
          border-left:unset;
          border-right: unset;
        }
      }
    }
  }
  &.global-search {
    max-width: 270px;
      &.desktop {
          @include media-breakpoint-down(lg) {
            display: none;
          }
        }
    
        &.mobile {
          display:none;
          @include media-breakpoint-down(lg) {
            display: flex;
            max-width: unset;
          }
        }

  }

  
  
}