@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

/* Padding classes */
.p-unset { padding:unset !important;}
.pb-unset { padding-bottom:unset !important;}
.pt-unset { padding-top:unset !important; }
.pl-unset { padding-left:unset !important; }
.pr-unset { padding-right: unset !important}

.pt-2-px { padding-top: 2px !important; }
.pr-2-px { padding-right: 2px !important; }
.pb-2-px { padding-bottom: 2px !important; }
.pl-2-px { padding-left: 2px !important; }

.pt-4-px { padding-top: 4px !important; }
.pr-4-px { padding-right: 4px !important; }
.pb-4-px { padding-bottom: 4px !important; }
.pl-4-px { padding-left: 4px !important; }

.pt-6-px { padding-top: 6px !important; }
.pr-6-px { padding-right: 6px !important; }
.pb-6-px { padding-bottom: 6px !important; }
.pl-6-px { padding-left: 6px !important; }

.pt-8-px { padding-top: 8px !important; }
.pr-8-px { padding-right: 8px !important; }
.pb-8-px { padding-bottom: 8px !important; }
.pl-8-px { padding-left: 8px !important; }

.pt-10-px { padding-top: 10px !important; }
.pr-10-px { padding-right: 10px !important; }
.pb-10-px { padding-bottom: 10px !important; }
.pl-10-px { padding-left: 10px !important; }

.pt-12-px { padding-top: 12px !important; }
.pr-12-px { padding-right: 12px !important; }
.pb-12-px { padding-bottom: 12px !important; }
.pl-12-px { padding-left: 12px !important; }

.pt-14-px { padding-top: 14px !important; }
.pr-14-px { padding-right: 14px !important; }
.pb-14-px { padding-bottom: 14px !important; }
.pl-14-px { padding-left: 14px !important; }

.pt-16-px { padding-top: 16px !important; }
.pr-16-px { padding-right: 16px !important; }
.pb-16-px { padding-bottom: 16px !important; }
.pl-16-px { padding-left: 16px !important; }

.pt-18-px { padding-top: 18px !important; }
.pr-18-px { padding-right: 18px !important; }
.pb-18-px { padding-bottom: 18px !important; }
.pl-18-px { padding-left: 18px !important; }

.pt-20-px { padding-top: 20px !important; }
.pr-20-px { padding-right: 20px !important; }
.pb-20-px { padding-bottom: 20px !important; }
.pl-20-px { padding-left: 20px !important; }

.pt-22-px { padding-top: 22px !important; }
.pr-22-px { padding-right: 22px !important; }
.pb-22-px { padding-bottom: 22px !important; }
.pl-22-px { padding-left: 22px !important; }

.pt-24-px { padding-top: 24px !important; }
.pr-24-px { padding-right: 24px !important; }
.pb-24-px { padding-bottom: 24px !important; }
.pl-24-px { padding-left: 24px !important; }

.pt-26-px { padding-top: 26px !important; }
.pr-26-px { padding-right: 26px !important; }
.pb-26-px { padding-bottom: 26px !important; }
.pl-26-px { padding-left: 26px !important; }

.pt-28-px { padding-top: 28px !important; }
.pr-28-px { padding-right: 28px !important; }
.pb-28-px { padding-bottom: 28px !important; }
.pl-28-px { padding-left: 28px !important; }

.pt-30-px { padding-top: 30px !important; }
.pr-30-px { padding-right: 30px !important; }
.pb-30-px { padding-bottom: 30px !important; }
.pl-30-px { padding-left: 30px !important; }

.pt-32-px { padding-top: 32px !important; }
.pr-32-px { padding-right: 32px !important; }
.pb-32-px { padding-bottom: 32px !important; }
.pl-32-px { padding-left: 32px !important; }

@include media-breakpoint-down(sm) {
    /* Margin classes */

    .p-unset-sm { padding:unset !important;}
    .pb-sm-unset { padding-bottom:unset !important;}
    .pt-sm-unset { padding-top:unset !important; }
    .pl-sm-unset { padding-left:unset !important; }
    .pr-sm-unset { padding-right: unset !important}


    .pt-2-sm-px { padding-top: 2px !important; }
    .pr-2-sm-px { padding-right: 2px !important; }
    .pb-2-sm-px { padding-bottom: 2px !important; }
    .pl-2-sm-px { padding-left: 2px !important; }
  
    .pt-4-sm-px { padding-top: 4px !important; }
    .pr-4-sm-px { padding-right: 4px !important; }
    .pb-4-sm-px { padding-bottom: 4px !important; }
    .pl-4-sm-px { padding-left: 4px !important; }
  
    .pt-6-sm-px { padding-top: 6px !important; }
    .pr-6-sm-px { padding-right: 6px !important; }
    .pb-6-sm-px { padding-bottom: 6px !important; }
    .pl-6-sm-px { padding-left: 6px !important; }
  
    .pt-8-sm-px { padding-top: 8px !important; }
    .pr-8-sm-px { padding-right: 8px !important; }
    .pb-8-sm-px { padding-bottom: 8px !important; }
    .pl-8-sm-px { padding-left: 8px !important; }
  
    .pt-10-sm-px { padding-top: 10px !important; }
    .pr-10-sm-px { padding-right: 10px !important; }
    .pb-10-sm-px { padding-bottom: 10px !important; }
    .pl-10-sm-px { padding-left: 10px !important; }
  
    .pt-12-sm-px { padding-top: 12px !important; }
    .pr-12-sm-px { padding-right: 12px !important; }
    .pb-12-sm-px { padding-bottom: 12px !important; }
    .pl-12-sm-px { padding-left: 12px !important; }
  
    .pt-14-sm-px { padding-top: 14px !important; }
    .pr-14-sm-px { padding-right: 14px !important; }
    .pb-14-sm-px { padding-bottom: 14px !important; }
    .pl-14-sm-px { padding-left: 14px !important; }
  
    .pt-16-sm-px { padding-top: 16px !important; }
    .pr-16-sm-px { padding-right: 16px !important; }
    .pb-16-sm-px { padding-bottom: 16px !important; }
    .pl-16-sm-px { padding-left: 16px !important; }
  
    .pt-18-sm-px { padding-top: 18px !important; }
    .pr-18-sm-px { padding-right: 18px !important; }
    .pb-18-sm-px { padding-bottom: 18px !important; }
    .pl-18-sm-px { padding-left: 18px !important; }
  
    .pt-20-sm-px { padding-top: 20px !important; }
    .pr-20-sm-px { padding-right: 20px !important; }
    .pb-20-sm-px { padding-bottom: 20px !important; }
    .pl-20-sm-px { padding-left: 20px !important; }
  
    .pt-22-sm-px { padding-top: 22px !important; }
    .pr-22-sm-px { padding-right: 22px !important; }
    .pb-22-sm-px { padding-bottom: 22px !important; }
    .pl-22-sm-px { padding-left: 22px !important; }
  
    .pt-24-sm-px { padding-top: 24px !important; }
    .pr-24-sm-px { padding-right: 24px !important; }
    .pb-24-sm-px { padding-bottom: 24px !important; }
    .pl-24-sm-px { padding-left: 24px !important; }
  
    .pt-26-sm-px { padding-top: 26px !important; }
    .pr-26-sm-px { padding-right: 26px !important; }
    .pb-26-sm-px { padding-bottom: 26px !important; }
    .pl-26-sm-px { padding-left: 26px !important; }
  
    .pt-28-sm-px { padding-top: 28px !important; }
    .pr-28-sm-px { padding-right: 28px !important; }
    .pb-28-sm-px { padding-bottom: 28px !important; }
    .pl-28-sm-px { padding-left: 28px !important; }
  
    .pt-30-sm-px { padding-top: 30px !important; }
    .pr-30-sm-px { padding-right: 30px !important; }
    .pb-30-sm-px { padding-bottom: 30px !important; }
    .pl-30-sm-px { padding-left: 30px !important; }
  
    .pt-32-sm-px { padding-top: 32px !important; }
    .pr-32-sm-px { padding-right: 32px !important; }
    .pb-32-sm-px { padding-bottom: 32px !important; }
    .pl-32-sm-px { padding-left: 32px !important; }
  }

  @include media-breakpoint-down(md) {
    /* Margin classes */

    .p-unset-md { padding:unset !important;}
    .pb-md-unset { padding-bottom:unset !important;}
    .pt-md-unset { padding-top:unset !important; }
    .pl-md-unset { padding-left:unset !important; }
    .pr-md-unset { padding-right: unset !important}


    .pt-2-md-px { padding-top: 2px !important; }
    .pr-2-md-px { padding-right: 2px !important; }
    .pb-2-md-px { padding-bottom: 2px !important; }
    .pl-2-md-px { padding-left: 2px !important; }
  
    .pt-4-md-px { padding-top: 4px !important; }
    .pr-4-md-px { padding-right: 4px !important; }
    .pb-4-md-px { padding-bottom: 4px !important; }
    .pl-4-md-px { padding-left: 4px !important; }
  
    .pt-6-md-px { padding-top: 6px !important; }
    .pr-6-md-px { padding-right: 6px !important; }
    .pb-6-md-px { padding-bottom: 6px !important; }
    .pl-6-md-px { padding-left: 6px !important; }
  
    .pt-8-md-px { padding-top: 8px !important; }
    .pr-8-md-px { padding-right: 8px !important; }
    .pb-8-md-px { padding-bottom: 8px !important; }
    .pl-8-md-px { padding-left: 8px !important; }
  
    .pt-10-md-px { padding-top: 10px !important; }
    .pr-10-md-px { padding-right: 10px !important; }
    .pb-10-md-px { padding-bottom: 10px !important; }
    .pl-10-md-px { padding-left: 10px !important; }
  
    .pt-12-md-px { padding-top: 12px !important; }
    .pr-12-md-px { padding-right: 12px !important; }
    .pb-12-md-px { padding-bottom: 12px !important; }
    .pl-12-md-px { padding-left: 12px !important; }
  
    .pt-14-md-px { padding-top: 14px !important; }
    .pr-14-md-px { padding-right: 14px !important; }
    .pb-14-md-px { padding-bottom: 14px !important; }
    .pl-14-md-px { padding-left: 14px !important; }
  
    .pt-16-md-px { padding-top: 16px !important; }
    .pr-16-md-px { padding-right: 16px !important; }
    .pb-16-md-px { padding-bottom: 16px !important; }
    .pl-16-md-px { padding-left: 16px !important; }
  
    .pt-18-md-px { padding-top: 18px !important; }
    .pr-18-md-px { padding-right: 18px !important; }
    .pb-18-md-px { padding-bottom: 18px !important; }
    .pl-18-md-px { padding-left: 18px !important; }
  
    .pt-20-md-px { padding-top: 20px !important; }
    .pr-20-md-px { padding-right: 20px !important; }
    .pb-20-md-px { padding-bottom: 20px !important; }
    .pl-20-md-px { padding-left: 20px !important; }
  
    .pt-22-md-px { padding-top: 22px !important; }
    .pr-22-md-px { padding-right: 22px !important; }
    .pb-22-md-px { padding-bottom: 22px !important; }
    .pl-22-md-px { padding-left: 22px !important; }
  
    .pt-24-md-px { padding-top: 24px !important; }
    .pr-24-md-px { padding-right: 24px !important; }
    .pb-24-md-px { padding-bottom: 24px !important; }
    .pl-24-md-px { padding-left: 24px !important; }
  
    .pt-26-md-px { padding-top: 26px !important; }
    .pr-26-md-px { padding-right: 26px !important; }
    .pb-26-md-px { padding-bottom: 26px !important; }
    .pl-26-md-px { padding-left: 26px !important; }
  
    .pt-28-md-px { padding-top: 28px !important; }
    .pr-28-md-px { padding-right: 28px !important; }
    .pb-28-md-px { padding-bottom: 28px !important; }
    .pl-28-md-px { padding-left: 28px !important; }
  
    .pt-30-md-px { padding-top: 30px !important; }
    .pr-30-md-px { padding-right: 30px !important; }
    .pb-30-md-px { padding-bottom: 30px !important; }
    .pl-30-md-px { padding-left: 30px !important; }
  
    .pt-32-md-px { padding-top: 32px !important; }
    .pr-32-md-px { padding-right: 32px !important; }
    .pb-32-md-px { padding-bottom: 32px !important; }
    .pl-32-md-px { padding-left: 32px !important; }
  }

  @include media-breakpoint-down(lg) {
    /* Margin classes */

    .p-unset-lg { padding:unset !important;}
    .pb-lg-unset { padding-bottom:unset !important;}
    .pt-lg-unset { padding-top:unset !important; }
    .pl-lg-unset { padding-left:unset !important; }
    .pr-lg-unset { padding-right: unset !important}

    .pt-2-lg-px { padding-top: 2px !important; }
    .pr-2-lg-px { padding-right: 2px !important; }
    .pb-2-lg-px { padding-bottom: 2px !important; }
    .pl-2-lg-px { padding-left: 2px !important; }
  
    .pt-4-lg-px { padding-top: 4px !important; }
    .pr-4-lg-px { padding-right: 4px !important; }
    .pb-4-lg-px { padding-bottom: 4px !important; }
    .pl-4-lg-px { padding-left: 4px !important; }
  
    .pt-6-lg-px { padding-top: 6px !important; }
    .pr-6-lg-px { padding-right: 6px !important; }
    .pb-6-lg-px { padding-bottom: 6px !important; }
    .pl-6-lg-px { padding-left: 6px !important; }
  
    .pt-8-lg-px { padding-top: 8px !important; }
    .pr-8-lg-px { padding-right: 8px !important; }
    .pb-8-lg-px { padding-bottom: 8px !important; }
    .pl-8-lg-px { padding-left: 8px !important; }
  
    .pt-10-lg-px { padding-top: 10px !important; }
    .pr-10-lg-px { padding-right: 10px !important; }
    .pb-10-lg-px { padding-bottom: 10px !important; }
    .pl-10-lg-px { padding-left: 10px !important; }
  
    .pt-12-lg-px { padding-top: 12px !important; }
    .pr-12-lg-px { padding-right: 12px !important; }
    .pb-12-lg-px { padding-bottom: 12px !important; }
    .pl-12-lg-px { padding-left: 12px !important; }
  
    .pt-14-lg-px { padding-top: 14px !important; }
    .pr-14-lg-px { padding-right: 14px !important; }
    .pb-14-lg-px { padding-bottom: 14px !important; }
    .pl-14-lg-px { padding-left: 14px !important; }
  
    .pt-16-lg-px { padding-top: 16px !important; }
    .pr-16-lg-px { padding-right: 16px !important; }
    .pb-16-lg-px { padding-bottom: 16px !important; }
    .pl-16-lg-px { padding-left: 16px !important; }
  
    .pt-18-lg-px { padding-top: 18px !important; }
    .pr-18-lg-px { padding-right: 18px !important; }
    .pb-18-lg-px { padding-bottom: 18px !important; }
    .pl-18-lg-px { padding-left: 18px !important; }
  
    .pt-20-lg-px { padding-top: 20px !important; }
    .pr-20-lg-px { padding-right: 20px !important; }
    .pb-20-lg-px { padding-bottom: 20px !important; }
    .pl-20-lg-px { padding-left: 20px !important; }
  
    .pt-22-lg-px { padding-top: 22px !important; }
    .pr-22-lg-px { padding-right: 22px !important; }
    .pb-22-lg-px { padding-bottom: 22px !important; }
    .pl-22-lg-px { padding-left: 22px !important; }
  
    .pt-24-lg-px { padding-top: 24px !important; }
    .pr-24-lg-px { padding-right: 24px !important; }
    .pb-24-lg-px { padding-bottom: 24px !important; }
    .pl-24-lg-px { padding-left: 24px !important; }
  
    .pt-26-lg-px { padding-top: 26px !important; }
    .pr-26-lg-px { padding-right: 26px !important; }
    .pb-26-lg-px { padding-bottom: 26px !important; }
    .pl-26-lg-px { padding-left: 26px !important; }
  
    .pt-28-lg-px { padding-top: 28px !important; }
    .pr-28-lg-px { padding-right: 28px !important; }
    .pb-28-lg-px { padding-bottom: 28px !important; }
    .pl-28-lg-px { padding-left: 28px !important; }
  
    .pt-30-lg-px { padding-top: 30px !important; }
    .pr-30-lg-px { padding-right: 30px !important; }
    .pb-30-lg-px { padding-bottom: 30px !important; }
    .pl-30-lg-px { padding-left: 30px !important; }
  
    .pt-32-lg-px { padding-top: 32px !important; }
    .pr-32-lg-px { padding-right: 32px !important; }
    .pb-32-lg-px { padding-bottom: 32px !important; }
    .pl-32-lg-px { padding-left: 32px !important; }
  }
  @include media-breakpoint-down(xl) {
    /* Margin classes */

    .p-unset-xl { padding:unset !important;}
    .pb-xl-unset { padding-bottom:unset !important;}
    .pt-xl-unset { padding-top:unset !important; }
    .pl-xl-unset { padding-left:unset !important; }
    .pr-xl-unset { padding-right: unset !important}


    .pt-2-xl-px { padding-top: 2px !important; }
    .pr-2-xl-px { padding-right: 2px !important; }
    .pb-2-xl-px { padding-bottom: 2px !important; }
    .pl-2-xl-px { padding-left: 2px !important; }
  
    .pt-4-xl-px { padding-top: 4px !important; }
    .pr-4-xl-px { padding-right: 4px !important; }
    .pb-4-xl-px { padding-bottom: 4px !important; }
    .pl-4-xl-px { padding-left: 4px !important; }
  
    .pt-6-xl-px { padding-top: 6px !important; }
    .pr-6-xl-px { padding-right: 6px !important; }
    .pb-6-xl-px { padding-bottom: 6px !important; }
    .pl-6-xl-px { padding-left: 6px !important; }
  
    .pt-8-xl-px { padding-top: 8px !important; }
    .pr-8-xl-px { padding-right: 8px !important; }
    .pb-8-xl-px { padding-bottom: 8px !important; }
    .pl-8-xl-px { padding-left: 8px !important; }
  
    .pt-10-xl-px { padding-top: 10px !important; }
    .pr-10-xl-px { padding-right: 10px !important; }
    .pb-10-xl-px { padding-bottom: 10px !important; }
    .pl-10-xl-px { padding-left: 10px !important; }
  
    .pt-12-xl-px { padding-top: 12px !important; }
    .pr-12-xl-px { padding-right: 12px !important; }
    .pb-12-xl-px { padding-bottom: 12px !important; }
    .pl-12-xl-px { padding-left: 12px !important; }
  
    .pt-14-xl-px { padding-top: 14px !important; }
    .pr-14-xl-px { padding-right: 14px !important; }
    .pb-14-xl-px { padding-bottom: 14px !important; }
    .pl-14-xl-px { padding-left: 14px !important; }
  
    .pt-16-xl-px { padding-top: 16px !important; }
    .pr-16-xl-px { padding-right: 16px !important; }
    .pb-16-xl-px { padding-bottom: 16px !important; }
    .pl-16-xl-px { padding-left: 16px !important; }
  
    .pt-18-xl-px { padding-top: 18px !important; }
    .pr-18-xl-px { padding-right: 18px !important; }
    .pb-18-xl-px { padding-bottom: 18px !important; }
    .pl-18-xl-px { padding-left: 18px !important; }
  
    .pt-20-xl-px { padding-top: 20px !important; }
    .pr-20-xl-px { padding-right: 20px !important; }
    .pb-20-xl-px { padding-bottom: 20px !important; }
    .pl-20-xl-px { padding-left: 20px !important; }
  
    .pt-22-xl-px { padding-top: 22px !important; }
    .pr-22-xl-px { padding-right: 22px !important; }
    .pb-22-xl-px { padding-bottom: 22px !important; }
    .pl-22-xl-px { padding-left: 22px !important; }
  
    .pt-24-xl-px { padding-top: 24px !important; }
    .pr-24-xl-px { padding-right: 24px !important; }
    .pb-24-xl-px { padding-bottom: 24px !important; }
    .pl-24-xl-px { padding-left: 24px !important; }
  
    .pt-26-xl-px { padding-top: 26px !important; }
    .pr-26-xl-px { padding-right: 26px !important; }
    .pb-26-xl-px { padding-bottom: 26px !important; }
    .pl-26-xl-px { padding-left: 26px !important; }
  
    .pt-28-xl-px { padding-top: 28px !important; }
    .pr-28-xl-px { padding-right: 28px !important; }
    .pb-28-xl-px { padding-bottom: 28px !important; }
    .pl-28-xl-px { padding-left: 28px !important; }
  
    .pt-30-xl-px { padding-top: 30px !important; }
    .pr-30-xl-px { padding-right: 30px !important; }
    .pb-30-xl-px { padding-bottom: 30px !important; }
    .pl-30-xl-px { padding-left: 30px !important; }
  
    .pt-32-xl-px { padding-top: 32px !important; }
    .pr-32-xl-px { padding-right: 32px !important; }
    .pb-32-xl-px { padding-bottom: 32px !important; }
    .pl-32-xl-px { padding-left: 32px !important; }
  }

  @include media-breakpoint-down(xxl) {
    /* Margin classes */

    .p-unset-xxl { padding:unset !important;}
    .pb-xxl-unset { padding-bottom:unset !important;}
    .pt-xxl-unset { padding-top:unset !important; }
    .pl-xxl-unset { padding-left:unset !important; }
    .pr-xxl-unset { padding-right: unset !important}


    .pt-2-xxl-px { padding-top: 2px !important; }
    .pr-2-xxl-px { padding-right: 2px !important; }
    .pb-2-xxl-px { padding-bottom: 2px !important; }
    .pl-2-xxl-px { padding-left: 2px !important; }
  
    .pt-4-xxl-px { padding-top: 4px !important; }
    .pr-4-xxl-px { padding-right: 4px !important; }
    .pb-4-xxl-px { padding-bottom: 4px !important; }
    .pl-4-xxl-px { padding-left: 4px !important; }
  
    .pt-6-xxl-px { padding-top: 6px !important; }
    .pr-6-xxl-px { padding-right: 6px !important; }
    .pb-6-xxl-px { padding-bottom: 6px !important; }
    .pl-6-xxl-px { padding-left: 6px !important; }
  
    .pt-8-xxl-px { padding-top: 8px !important; }
    .pr-8-xxl-px { padding-right: 8px !important; }
    .pb-8-xxl-px { padding-bottom: 8px !important; }
    .pl-8-xxl-px { padding-left: 8px !important; }
  
    .pt-10-xxl-px { padding-top: 10px !important; }
    .pr-10-xxl-px { padding-right: 10px !important; }
    .pb-10-xxl-px { padding-bottom: 10px !important; }
    .pl-10-xxl-px { padding-left: 10px !important; }
  
    .pt-12-xxl-px { padding-top: 12px !important; }
    .pr-12-xxl-px { padding-right: 12px !important; }
    .pb-12-xxl-px { padding-bottom: 12px !important; }
    .pl-12-xxl-px { padding-left: 12px !important; }
  
    .pt-14-xxl-px { padding-top: 14px !important; }
    .pr-14-xxl-px { padding-right: 14px !important; }
    .pb-14-xxl-px { padding-bottom: 14px !important; }
    .pl-14-xxl-px { padding-left: 14px !important; }
  
    .pt-16-xxl-px { padding-top: 16px !important; }
    .pr-16-xxl-px { padding-right: 16px !important; }
    .pb-16-xxl-px { padding-bottom: 16px !important; }
    .pl-16-xxl-px { padding-left: 16px !important; }
  
    .pt-18-xxl-px { padding-top: 18px !important; }
    .pr-18-xxl-px { padding-right: 18px !important; }
    .pb-18-xxl-px { padding-bottom: 18px !important; }
    .pl-18-xxl-px { padding-left: 18px !important; }
  
    .pt-20-xxl-px { padding-top: 20px !important; }
    .pr-20-xxl-px { padding-right: 20px !important; }
    .pb-20-xxl-px { padding-bottom: 20px !important; }
    .pl-20-xxl-px { padding-left: 20px !important; }
  
    .pt-22-xxl-px { padding-top: 22px !important; }
    .pr-22-xxl-px { padding-right: 22px !important; }
    .pb-22-xxl-px { padding-bottom: 22px !important; }
    .pl-22-xxl-px { padding-left: 22px !important; }
  
    .pt-24-xxl-px { padding-top: 24px !important; }
    .pr-24-xxl-px { padding-right: 24px !important; }
    .pb-24-xxl-px { padding-bottom: 24px !important; }
    .pl-24-xxl-px { padding-left: 24px !important; }
  
    .pt-26-xxl-px { padding-top: 26px !important; }
    .pr-26-xxl-px { padding-right: 26px !important; }
    .pb-26-xxl-px { padding-bottom: 26px !important; }
    .pl-26-xxl-px { padding-left: 26px !important; }
  
    .pt-28-xxl-px { padding-top: 28px !important; }
    .pr-28-xxl-px { padding-right: 28px !important; }
    .pb-28-xxl-px { padding-bottom: 28px !important; }
    .pl-28-xxl-px { padding-left: 28px !important; }
  
    .pt-30-xxl-px { padding-top: 30px !important; }
    .pr-30-xxl-px { padding-right: 30px !important; }
    .pb-30-xxl-px { padding-bottom: 30px !important; }
    .pl-30-xxl-px { padding-left: 30px !important; }
  
    .pt-32-xxl-px { padding-top: 32px !important; }
    .pr-32-xxl-px { padding-right: 32px !important; }
    .pb-32-xxl-px { padding-bottom: 32px !important; }
    .pl-32-xxl-px { padding-left: 32px !important; }
  }

