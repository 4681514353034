.select-button-control-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    margin-bottom:16px;

    &.no-label {
        gap: unset;
    }
    label {
      @include fontInter(12px, #000, 500);
      
    }

    p-selectButton {
        .p-selectbutton .p-button {
            background-color: #EFEFEF;
            height: 40px;
            @include fontInter(14px, #000, 500);

            &.p-disabled {
                opacity: 1;
                color: #94a3b8;
            }
        }
    }

    &.secondary {
        p-selectButton {
            .p-selectbutton   {
        
                @include fontInter(14px, #000, 500);

                .p-button {
                    background-color: #FFF;
                    height: 40px;
                }
            }

            .p-highlight {
           
                    @include fontInter(14px, #FFF, 500);
                
                &::before {
                    background-color: #000;
                    @include fontInter(14px, #FFF, 500);
                }
            }
        }
    }
}

